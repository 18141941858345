import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyEn = () => {
  //const { t, i18n, ready } = useTranslation("en",{ useSuspense: false });
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <section className="faqWrapSec cwsSec pt-12 pt-md-15 py-md-9 pt-lg-17 pb-10 pb-lg-10 pt-xl-19 pb-xl-13">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="headerII ltrSpce mb-8 mb-md-10 mb-lg-12 px-md-6 px-xl-10">
                <h3 className="h3 text-center fwEbold mb-4 mb-sm-5 mb-xl-6 mx-lg-12">
                Privacy Policy
                </h3>
                <h5>We respect your privacy</h5>
                <p>We are deeply committed to protecting the privacy of our customers.  The following privacy policy sets out what information may be collected and how it will be used to improve your website / mobile application experience.By using our site, you agree to the terms of this Privacy Policy.  Please review the following privacy policy carefully and make sure you understand it well. If you have any questions or concerns about this Privacy Policy, please contact us at this email: info@blueteamuae.com</p>
                <h5>Information we collect</h5>
                <p>Non-personal information and data may be collected automatically, according to the standard operations of the Company's computer servers, or by using 'cookies'.  Cookies are files that a website / mobile application usually uses to recognize its visitors. Besides, enabling websites to track users' attitudes and preferences. Cookies fill up a little space on your computer and are harmless to your files.  Cookies work by assigning a number to the user that can only be used or identified by the designated site.  Users should know that the Company cannot control the use of cookies (or information generated by them) from third parties.  If you do not want information to be collected by cookies, your browser allows you to refuse or accept them.  However, there may be some features and services that require cookies to personalize information access to you.</p>
                <h5>How do we use your information</h5>
                <p>Our main purpose of information is using them to provide improved services, which fulfill our customers' needs. We use this information to identify the most wanted services, enable you to view your browsing history, and to store your credit card information. Moreover, users' information is required for promotions and evaluating browsing. Finally, we benefit from users' information to determine which services we need to improve, such as using methods and geographic locations to determine the best locations for our services. We collect Mobile information so that we can develop appropriate mobile apps, which are compatible with various types of devices, also for troubleshooting in some cases, and for marketing purposes. Our Company uses your Internet Protocol (IP) address to help diagnose problems with our computer server and to help better administer the Site.  Your IP address is used to help identify you and to gather extensive statistical data.  Your IP address does not contain any personal information.</p>
                <h5>Disclosure of your information</h5>
                <p>We do not sell, rent, or trade your personal or geolocation information.  We will only use this information as stated in this Privacy Policy.  Our Company may share collected information if it includes non-identifying information and registration data with third parties to perform analytical studies and statistical descriptions and to deliver targeted advertisements about other products and services.Our Company cooperates with government, legal officials, and private parties to enforce and comply with the law.  We will disclose any information about you to government, law enforcement officials, or private parties for the following reasons:In our sole discretion, we believe it is necessary or appropriate to respond to legal claims and processes (including for example subpoenas).To protect the property and rights of the Company or a third party.To protect the safety of the public or any person.To prevent or stop any activity that we may consider dangerous or risky due to being illegal or immoral or any activity for which a lawful response may be taken.We use a third party hosting service provider that hosts our support section of the website.  Information collected in this section of the website / mobile application is subject to our Privacy Policy.  This third party service provider does not have access to this information.</p>
                <h5>Safety</h5>
                <p>The personal identification information and geolocation information that we collect is stored securely in our database, and we use industry standard and commercially accepted security methods such as encryption, firewalls, and SSL (Secure Sockets Layers) to protect your information.  However, despite the high effectiveness of encryption technology, no security system is impenetrable.  We cannot guarantee the security of our database, nor can we guarantee that information you provide to us will not be intercepted while being transmitted to us over the Internet, and any information you transmit to us is at your own risk. We recommend that you do not disclose your password to anyone. </p>
                <h5>Modifications to this Privacy Policy</h5>
                <p>We may update this privacy statement to accommodate changes in our information practices.  If we make any changes, we will notify you via email (send an email to the email address specified in your account), or by notifications on this Site prior to the change becoming effective.  We encourage you to check this page regularly for the latest information on our privacy practices. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyEn;
