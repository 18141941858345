// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC2JVLqqv0Pe_2jpi-zfEt4mhDaJcoLpWQ",
  authDomain: "blue-team-554f8.firebaseapp.com",
  databaseURL: "https://blue-team-554f8.firebaseio.com",
  projectId: "blue-team-554f8",
  storageBucket: "blue-team-554f8.appspot.com",
  messagingSenderId: "592322326289",
  appId: "1:592322326289:web:bd2293d63e151e5f9226b0"
};
export default firebaseConfig
// Initialize Firebase
