import { Icon } from "@iconify/react";
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
//import Modal from "react-bootstrap/Modal";

export default function DownloadApp({
  showapp,
  setShowApp,
  handleClose,
  logEvent,
  analytics,
}) {
  return (
    // <Modal size="lg" show={showapp} onHide={handleClose}>
    //   <Icon
    //     style={{ cursor: "pointer", padding: 5 }}
    //     onClick={handleClose}
    //     fontSize={35}
    //     icon="solar:close-square-linear"
    //   ></Icon>
      <Row>
        <Col
          sm={12}
          lg={7}
          className="d-flex flex-column col-sm-12 p-3"
          style={{
            flexDirection: "col",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 dir="ltr" style={{ padding: 5 }}>
            <span style={{ color: "#000" }}>Download</span> Blue Team Car
            Services App Now!
          </h3>
          <p style={{ fontSize: 14, padding: 5 }}>
            استمتعوا بالعرض الأول من نوعه في أبوظبي حمل التطبيق الان واستمتع
            بالخصم الحصري احصل علي 10% رصيد إضافي عند كل حجز بالتطبيق لا تدع هذا
            العرض الرائع يفوتك اضغط علي الرابط لتقوم بالتحميل والبدء في الحجز
            والاستمتاع بجميع الخدمات بأفضل الأسعار
          </p>
          <p style={{ fontSize: 14, padding: 5 }}>
            Ready for amazing deals? Download our app now and enjoy an exclusive
            offer: Get 10% cashback on every booking you make! 🎉 Don't miss out
            on this fantastic opportunity to save while you explore. Click the
            link to download and start booking with cashback today
          </p>
          <Row>
            <a
              dir="ltr"
              type="button"
              style={{
                background: "#141414",
                borderRadius: 50,
                color: "#fff",
                padding: 10,
                border: "none",
                margin: 5,
              }}
              onClick={() => {
                logEvent(analytics, "android_app_download");
              }}
              href="https://play.google.com/store/apps/details?id=com.blueteam"
            >
              <Icon fontSize={20} icon="devicon-plain:android"></Icon>Google
              Play
            </a>
            <a
              dir="ltr"
              type="button"
              style={{
                background: "#141414",
                borderRadius: 50,
                color: "#fff",
                padding: 10,
                border: "none",
                margin: 5,
              }}
              onClick={() => {
                logEvent(analytics, "ios_app_download");
              }}
              href="https://apps.apple.com/us/app/blue-team-car-services/id6451065154"
            >
              <Icon fontSize={20} icon="uil:apple"></Icon>App Store
            </a>
          </Row>
        </Col>
        <Col sm={12} lg={5} className="p-5">
          <img
            src="images/mobileapp.jpg"
            alt="image description"
            className="img-fluid m-3"
          />
        </Col>
      </Row>
    // </Modal>
  );
}
