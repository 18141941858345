const data =[
    {
        id:1,
        image:"/images/gallery/one.webp",
        categoryEN:"Car Detailing", 
        categoryAR:'صيانة السيارة'
    },
    {
        id:145,
        image:"/images/gallery/detail1.webp",
        categoryEN:"Car Detailing", 
        categoryAR:'صيانة السيارة'
    },
    {
        id:143,
        image:"/images/gallery/detail2.webp",
        categoryEN:"Car Detailing", 
        categoryAR:'صيانة السيارة'
    },
    {
        id:11,
        image:"/images/gallery/car-wash1.webp",
        categoryEN:"Car Wash",
        categoryAR:'غسيل السيارة'
    },
    {
        id:12,
        image:"/images/gallery/car-wash3.webp",
        categoryEN:"Car Wash",
        categoryAR:'غسيل السيارة'
    },
    {
        id:13,
        image:"/images/gallery/car-wash4.webp",
        categoryEN:"Car Wash",
        categoryAR:'غسيل السيارة'
    },
    {
        id:22,
        image:"/images/gallery/car-wash2.webp",
        categoryEN:"Car Wash",
        categoryAR:'غسيل السيارة'
    },
    // {
    //     id:22,
    //     image:"/images/gallery/car-wash2.png",
    //     categoryEN:"Car Detailing", 
    //     categoryAR:'صيانة السيارة'
    // },
    {
        id:3,
        image:"/images/gallery/garage1.webp",
        categoryEN:"Car Garage",
        categoryAR:'مركز الخدمه '
    },
    {
        id:322,
        image:"/images/gallery/garage2.webp",
        categoryEN:"Car Garage",
        categoryAR:'مركز الخدمه '
    },
    {
        id:321,
        image:"/images/gallery/garage3.webp",
        categoryEN:"Car Garage",
        categoryAR:'مركز الخدمه '
    },
    {
        id:4,
        image:"/images/gallery/TIRES.webp",
        categoryEN:"Car Garage",
        categoryAR:'مركز الخدمه '
    },
  
    {
        id:6,
        image:"/images/gallery/two.webp",
        categoryEN:"Car Detailing", 
        categoryAR:'صيانة السيارة'
    }
]
export default data;