import React from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import api from "../../constant/api";
import message from "../../constant/Message";
import i18next from "i18next";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

function HomeBookingForm({
  show,
  category,
  defaultCategory = "",
  defaultPackage = "",
  logEvent,
  analytics

}) {
  const [packages, setPackage] = React.useState();
  const [values, setValues] = React.useState({
    we_category: defaultCategory,
    we_package: defaultPackage,
    we_date: "",
    we_time: "",
    we_name: "",
    we_phone: "",
    we_email: "",
    we_status: "0",
    we_done_by: "",
    we_amount: "",
    we_type: "booking_form",
    we_notes: "",
  });
  const bookEnquiry = () => {
    logEvent(analytics,'homepage_booking_form')

    if (values.we_name != "" && values.we_phone != "") {
        values.we_date = moment().format('DD-MM-YYYY')
        values.we_time = moment().format('h:mm')
      api
        .post("/website/addBookingEnquiry", values)
        .then((res) => {
          if (res.status == 200) {
            message(
              "Thanks For Contacting Us. Our 24*7 support will reach you soon",
              "success"
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            message("Try Again Later", "warning");
          }
        })
        .catch((err) => {
          message("Please Check your internet Connection", "error");
        });
    } else {
      message("Name And Phone number is required", "warning");
    }
  };
    const getPackagesCatId = (sp_cat_id) => {
      var result = category.find(
        (item) => item.service_category_name_ar === sp_cat_id
      );
      if(result){
        api
        .post("/service/getServiceByCategoryId", {
          sp_cat_id: result.service_category_id,
        })
        .then((res) => {
          setPackage(res.data.data);
        })
        .catch((err) => {
          message("Please Check your internet Connection", "error");
        });
    };

     };
  const handleInputs = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  React.useEffect(() => {
    //   getPackagesCatId(defaultCategory)
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <ToastContainer></ToastContainer>
      <section className="widget">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="header ltrSpce text-center mb-10 mb-xl-11">
            <h3 className="h3 fwEbold mb-4">{t("book_Enq")}</h3>
              <p className="psudo">{t("book-Appoint-head")}</p>
            </div>
          </div>
        </div>
       
        </div>
        <form className="contctWidgetForm btnTheme  pt-4 px-4  pb-6">
          
          <Row className="mt-3">
            <Col sm={12} lg={3}>
              <select
                onChange={(e) => {
                  handleInputs(e);
                  getPackagesCatId(e.target.value);
                }}
                className="form-control form-select"
                name="we_category"
              >
                <option selected>{t("category")}</option>
                {category &&
                  category.map((cat) => (
                    <option
                      key={cat.service_category_id}
                      selected={
                        defaultCategory == cat.service_category_name_ar && true
                      }
                      value={cat.service_category_name_ar}
                    >
                      {cat.service_category_name_ar}|
                      {cat.service_category_name_en}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={12} lg={3}>
              <select
                onChange={handleInputs}
                name="we_package"
                className="form-control form-select"
              >
                <option selected>{t("package")}</option>
                {packages &&
                  packages.map((pack) => (
                    <option
                      key={pack.sp_id}
                      selected={defaultPackage == pack.sp_name_ar && true}
                      value={pack.sp_name_ar}
                    >
                      {i18next.language == "ar"
                        ? pack.sp_name_ar
                        : pack.sp_name_en}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={12} lg={3}>
              <input
                className="form-control"
                type="date"
                name="we_date"
                onChange={handleInputs}
              />
            </Col>
            <Col sm={12} lg={3}>
              <input
                className="form-control"
                type="time"
                onChange={handleInputs}
                name="we_time"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="12" xl={4}>
              <input
                type="text"
                className="form-control rounded"
                placeholder={t("Full_Name")}
                name="we_name"
                onChange={handleInputs}
              />
            </Col>
            <Col sm="12" xl={4}>
              <input
                type="text"
                className="form-control rounded"
                placeholder={t("phone_no")}
                name="we_phone"
                onChange={handleInputs}
              />
            </Col>
            <Col sm="12" xl={4}>
              <input
                type="email"
                className="form-control rounded"
                placeholder={
                  i18next.language == "ar"
                    ? "عنوان البريد الإلكتروني "
                    : "email address"
                }
                name="we_email"
                onChange={handleInputs}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} lg={3}>
              <textarea
                className="form-control rounded"
                name="we_notes"
                placeholder={t("Notes")}
                onChange={handleInputs}
              ></textarea>
            </Col>
            <Col sm={12} lg={3}>
              <button
                type="button"
                onClick={() => {
                  bookEnquiry();
                }}
                className="mt-5 btn btnTheme text-uppercase  w-100 fwEbold rounded border-white"
                style={{ borderWidth: 1, borderColor: "#fff !important" }}
              >
                {t("book&send")}
              </button>
            </Col>
          </Row>
        </form>
      </section>{" "}
    </>
  );
}

export default HomeBookingForm;
